<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/goods' }">商品列表</el-breadcrumb-item>
      <el-breadcrumb-item>添加商品限购组合</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片区域 -->
    <el-card>
      <div class="table" style="width: 50%; margin-top: 10px">
        <el-form :model="addGoodsForm" ref="addGoodsFormRef" label-width="100px">
          <el-form-item label="组合名称" prop="title">
            <el-input v-model="addGoodsForm.title"></el-input>
          </el-form-item>
          <el-form-item label="限购数量" prop="title">
            <el-input v-model="addGoodsForm.count"></el-input>
          </el-form-item>
          <el-form-item label="包含商品" prop="goods">
            <el-row class="select-row" v-for="(item,index) in addGoodsForm.goods" :key="index">
              <el-select v-model="addGoodsForm.goods[index]" >
                <el-option v-if="goodsDetailList[index]" :label="`${goodsDetailList[index].goodsId}-${goodsDetailList[index].remark}-${goodsDetailList[index].title}`" :value="goodsDetailList[index].goodsId"></el-option>
                <el-option v-for="(item,idx) in goodsList" :key="idx" :label="`${item.goodsId} ${item.title}`" :value="item.goodsId"></el-option>
              </el-select>
              <i class="close-btn el-icon-close" @click="deleteOne(index)"></i>
            </el-row>
            
            <el-row>
              <div class="add-btn" @click="addOne">添加商品</div>
            </el-row>
          </el-form-item>

          <!-- 操作 -->
          <el-form-item>
            <el-button type="primary" class="btnAdd" @click="addGoods()">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

  </div>
</template>

<script>
export default {
  data () {
    return {
      goodsList: [],
      goodsDetailList: [],
      addGoodsForm: {
        title: '',
        count: 999,
        goods: []
      },
      isEdit: false,
      groupInfo: null
    } 
  },
  created () {
    const {query} = this.$route;
    if(query.id){
      this.groupInfo = localStorage.getItem(query.id);
      if(this.groupInfo){
        this.groupInfo = JSON.parse(this.groupInfo);
        this.isEdit = true;
        this.addGoodsForm.title = this.groupInfo.goodsLimitName;
        this.addGoodsForm.count = this.groupInfo.goodsLimitCount;
        this.addGoodsForm.goods = this.groupInfo.goods.map(o => o.goodsId);
        this.goodsDetailList = this.groupInfo.goods;
      }
    }
    // 商品列表
    this.getGoods()
  },
  methods: {
    //  获取商品列表信息
    getGoods () {
      var params = {};
      if(this.isEdit){
        params.groupId = this.groupInfo.goodsLimitId
      }
      this.$api.QueryGoodsToLimitPurchaseGroup(params).then((res) => {
        if (res.code !== 200) {
          return this.$message.error('请求商品列表失败')
        } 
        this.goodsList = res.data
        if(!this.isEdit){
          this.addGoodsForm.goods = [this.goodsList[0].goodsId];
        }
      })
    },
    addOne(){
      const {goods} = this.addGoodsForm;
      let list = this.goodsList.filter(o => !goods.includes(o.goodsId))
      if(list.length < 1) return this.$message.error('无可添加商品')
      goods.push(list[0].goodsId)
      this.addGoodsForm.goods = [...goods];
    },
    deleteOne(i){
      if(this.addGoodsForm.goods.length < 2) return this.$message.error('至少添加一个商品')
      let list = this.addGoodsForm.goods.filter((o, idx) => idx != i);
      this.addGoodsForm.goods = [...list];
    },
    // 添加商品
    addGoods () {
      if(!this.addGoodsForm.title) return this.$message.error('请输入组合名称');
      
      if(this.isEdit){
        var params = {
          goodsLimitId: this.groupInfo.goodsLimitId,
          goodsLimitName: this.addGoodsForm.title,
          goodsLimitCount: this.addGoodsForm.count,
          goodsId: this.addGoodsForm.goods
        }
        this.$api.UpdateAddDeleteGoodsLimitPurchaseInfo(params).then((res) => {
          if (res.code !== 200) {
            return this.$message.error('编辑商品限购组合失败')
          } 
          this.$message.success('编辑商品限购组合成功')
          this.$router.push('/limits')
        })
      }else{
        var params = {
          goodsLimitName: this.addGoodsForm.title,
          goodsLimitCount: this.addGoodsForm.count,
          goodsId: this.addGoodsForm.goods
        }
        this.$api.AddGoodsLimitPurchaseGroups(params).then((res) => {
          if (res.code !== 200) {
            return this.$message.error('添加商品限购组合失败')
          } 
          this.$message.success('添加商品限购组合成功')
          this.$router.push('/limits')
        })
      }
      
    },

  },
}
</script>

<style lang="scss" scoped>
  .el-select{
    width:100%;
    margin-bottom: 10px;
  }
  .add-btn{
    width: 100%;
    border: 1px dashed #ccc;
    line-height: 40px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
  }
  .select-row{
    position: relative;

  }
  .close-btn{
    position: absolute;
    right: -20px;
    top: 14px;
    cursor: pointer
  }
</style>
